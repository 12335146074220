<template>
  <div class="card" :class="loader ? 'cursor-pointer-none' : ''">
    <div class="card-header card-header-stretch overflow-auto">
      <ul
        class="
          nav nav-stretch nav-line-tabs
          fw-bold
          border-transparent
          flex-nowrap
        "
        role="tablist"
        id="kt_layout_builder_tabs"
      >
        <li class="nav-item">
          <a
            class="nav-link fs-5"
            :class="active_tab == 'quiz' ? 'active' : ''"
            data-bs-toggle="tab"
            @click="active_tab = 'quiz'"
            href="#"
            role="tab"
            >Quiz</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link fs-5"
            :class="active_tab == 'group' ? 'active' : ''"
            @click="active_tab = 'group'"
            data-bs-toggle="tab"
            href="#"
            role="tab"
            >Group</a
          >
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content pt-3">
        <div class="tab-pane active" v-if="active_tab == 'quiz'">
          <div class="row">
            <div class="card shadow-none p-0">
              <div class="card-header border-0">
                <div class="card-title">
                  <div class="d-flex align-items-center position-relative my-1">
                    <select
                      class="
                        form-select form-select-solid
                        group-dropdown
                        w-250px
                      "
                      aria-label="View By Group"
                      id="sortBy"
                      v-model="sort_group_id"
                      @change="getquizData(current_page)"
                    >
                      <option class="text-muted" value="">View By Group</option>
                      <option
                        v-for="(g, index) in getquizGroup"
                        :key="index"
                        :value="g.id"
                      >
                        {{ g.name }}
                      </option>
                    </select>
                    <a
                      @click="showmodelQuizGroup()"
                      class="
                        btn btn-icon btn-bg-light btn-active-color-primary
                        me-1
                        add-button
                        group-modal-btn
                      "
                    >
                      <i class="fa fa-plus fa-lg"></i>
                    </a>
                  </div>
                </div>
                <div class="card-toolbar">
                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="17.0365"
                        y="15.1223"
                        width="8.15546"
                        height="2"
                        rx="1"
                        transform="rotate(45 17.0365 15.1223)"
                        fill="black"
                      ></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    data-kt-subscription-table-filter="search"
                    class="
                      form-control form-control-solid
                      w-250px
                      ps-14
                      search-input
                    "
                    placeholder="Search Quiz"
                    id="searchInput"
                    @input="search_quiz()"
                    v-model="quiz_name_search"
                  />
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="showmodelQuiz()"
                    >
                      Add Quiz
                    </button>
                  </div>
                  <div
                    class="d-flex justify-content-end align-items-center d-none"
                    data-kt-subscription-table-toolbar="selected"
                  >
                    <div class="fw-bolder me-5">
                      <span
                        class="me-2"
                        data-kt-subscription-table-select="selected_count"
                      ></span
                      >Selected
                    </div>
                    <button
                      type="button"
                      class="btn btn-danger"
                      data-kt-subscription-table-select="delete_selected"
                    >
                      Delete Selected
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="table-responsive">
                  <div
                    id="quiz_table_wrapper"
                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div class="table-responsive">
                      <table
                        class="
                          table
                          align-middle
                          table-row-dashed
                          fs-6
                          gy-5
                          dataTable
                          no-footer
                        "
                        id="quiz_table"
                        style="width: 1170px"
                      >
                        <thead>
                          <tr
                            class="
                              text-start text-muted
                              fw-bolder
                              fs-7
                              text-uppercase
                              gs-0
                            "
                          >
                            <th class="w-10px pe-2">
                              <div
                                class="
                                  form-check
                                  form-check-sm
                                  form-check-custom
                                  form-check-solid
                                  me-3
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  data-kt-check="true"
                                  data-kt-check-target="#quiz_table .form-check-input"
                                  value="1"
                                />
                              </div>
                            </th>
                            <th class="cursor-pointer">Quiz Name</th>
                            <th class="cursor-pointer">Date</th>
                            <th>Views</th>
                            <th>Entries</th>
                            <th>Conv%</th>
                            <th>%Qualified</th>
                            <th>Tag fires</th>
                            <th>Tag</th>
                            <th>Link</th>
                            <th class="text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody
                          class="text-gray-600 fw-bold"
                          v-if="
                            getquizDataGT &&
                            getquizDataGT.data &&
                            getquizDataGT.data.length
                          "
                        >
                          <tr
                            class="odd"
                            v-for="(q, index) in getquizDataGT.data"
                            :key="index"
                          >
                            <td>
                              <div
                                class="
                                  form-check
                                  form-check-sm
                                  form-check-custom
                                  form-check-solid
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="1"
                                />
                              </div>
                            </td>
                            <td>
                              <div id="quiz_row_edit_73">
                                <span class="edit-name-show">{{ q.name }} <span class="fs-8" v-if="q.is_copy">(Copy)</span> </span>
                                <!-- <span
                                                                    class="svg-icon svg-icon-3 cursor-pointer"
                                                                    onclick="edit_text(73)"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            opacity="0.3"
                                                                            d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                                            fill="black"
                                                                        ></path>
                                                                        <path
                                                                            d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                                            fill="black"
                                                                        ></path>
                                                                    </svg>
                                                                </span> -->
                              </div>
                              <!-- <div
                                                                id="quiz_row_update_73"
                                                                class="d-none"
                                                            >
                                                                <input
                                                                    id="edit_value_name_73"
                                                                    class="edit-name"
                                                                    value="Uzair"
                                                                /><i
                                                                    class="fas fa-check cursor-pointer"
                                                                    onclick="updateName(73)"
                                                                ></i>
                                                            </div> -->
                            </td>
                            <td class="sorting_1">
                              {{ format_date(q.created_at) }}
                            </td>
                            <td>{{q.total_view}}</td>
                            <td>{{q.entries_count}}</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>
                              <a
                                class="
                                  tag-modal-btn
                                  btn
                                  btn-icon
                                  btn-bg-light
                                  btn-active-color-primary
                                  btn-sm
                                  me-1 cursor-pointer
                                "
                                ><span class="me-2"> 0</span
                                ><i class="fa fa-tag fa-lg"></i>
                              </a>
                            </td>
                            <td>
                              <a
                                class="
                                  btn
                                  btn-icon
                                  btn-bg-light
                                  btn-active-color-primary
                                  btn-sm
                                  me-1 cursor-pointer
                                " @click="copyLink(q.unique_code)"
                                ><span class="me-2"> </span
                                ><i class="fa fa-copy fa-lg"></i>
                              </a>
                            </td>
                            <td>
                              <div
                                class="d-flex justify-content-end flex-shrink-0"
                              >
                                <router-link
                                  class="
                                    btn
                                    btn-icon
                                    btn-bg-light
                                    btn-circle
                                    btn-active-color-primary
                                    btn-sm
                                    me-1 cursor-pointer
                                  "
                                  :to="{
                                    name: 'QuizDetail',
                                    params: { id: q.id },
                                  }"
                                >
                                  <span class="svg-icon svg-icon-3">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                        fill="black"
                                      ></path>
                                      <path
                                        d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                        fill="black"
                                      ></path>
                                    </svg>
                                  </span>
                                </router-link>
                                <a
                                  href="javascript:void(0)"
                                  class="
                                    btn
                                    btn-icon
                                    btn-bg-light
                                    btn-circle
                                    btn-active-color-primary
                                    btn-sm
                                    me-1 cursor-pointer
                                    show menu-dropdown
                                  "
                                  :class="q.showedit==true ? 'show menu-dropdown' : ''"
                                  @click="q.showedit=true"
                                  data-kt-menu-trigger="click"
                                  data-kt-menu-placement="bottom-end"
                                  data-kt-menu-flip="top-end"
                                >
                                  <span class="svg-icon svg-icon-3">
                                    <i class="fas fa-ellipsis-v"></i>
                                  </span>
                                </a>
                                <div
                                  class="
                                    menu
                                    menu-sub
                                    menu-sub-dropdown
                                    menu-column
                                    menu-rounded
                                    menu-gray-600
                                    menu-state-bg-light-primary
                                    fw-bold
                                    fs-7
                                    w-125px
                                    py-2
                                    
                                  "
                                   @mouseleave="q.showedit=false"
                                  :class="q.showedit==true ? 'show' : ''"
                                  data-kt-menu="true"
                                  :style="q.showedit==true ? 'z-index: 105; position: fixed ' : ''"
                                >
                                  <div class="menu-item px-3">
                                    <a
                                      href="javascript:void(0)"
                                      @click="editQuiz(q)"
                                      class="menu-link px-2 edit-campaign-btn"
                                    >
                                      Edit
                                    </a>
                                  </div>
                                  <div class="menu-item px-3">
                                    <a
                                      href="javascript:void(0)"
                                      class="menu-link px-2"
                                      @click="cloneQuizData(q)"
                                    >
                                      Clone
                                    </a>
                                  </div>
                                  <!-- <div class="menu-item px-3">
                                    <a
                                      href="javascript:void(0)"
                                      class="menu-link px-2"
                                      @click="copyScript(q)"
                                    >
                                      Script
                                    </a>
                                  </div> -->
                                  <div class="menu-item px-3">
                                    <router-link class="menu-link px-2" :to="{ name: 'QuizDetail', params: { id: q.id }, query: { tab: 'entries' }}">
                                    Entries
                                  </router-link>
                                  </div>
                                  <div class="menu-item px-3" v-if="q.quizresponse_count != 0">
                                    <router-link class="menu-link px-2" :to="{ name: 'QuizDetail', params: { id: q.id }, query: { tab: 'statistics' }}">
                                    Statistics
                                  </router-link>
                                  </div>
                                  <div class="menu-item px-3">
                                    <a
                                      href="javascript:void(0)"
                                      class="menu-link px-2"
                                      @click="deleteConQuiz(q.id)"
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="11">No Quizes Found.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer clearfix d-flex justify-content-end">
                <Pagination
                  :data="getquizDataGT"
                  :show-disabled="true"
                  size="small"
                  @pagination-change-page="getquizData"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane active" v-else-if="active_tab == 'group'">
          <div class="row">
            <div class="card shadow-none p-0">
              <div class="card-header border-0 p-0">
                <div class="card-title">
                  <div class="d-flex align-items-center position-relative my-1">
                    <span class="svg-icon svg-icon-1 position-absolute ms-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="17.0365"
                          y="15.1223"
                          width="8.15546"
                          height="2"
                          rx="1"
                          transform="rotate(45 17.0365 15.1223)"
                          fill="black"
                        ></rect>
                        <path
                          d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                          fill="black"
                        ></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      class="form-control form-control-solid w-250px ps-14"
                      id="searchInputGroup"
                      placeholder="Search Group"
                      @input="search_group()"
                      v-model="quiz_serch"
                    />
                  </div>
                </div>
                <div class="card-toolbar">
                  <div
                    class="d-flex justify-content-end"
                    data-kt-subscription-table-toolbar="base"
                  >
                    <button
                      type="button"
                      class="btn btn-primary group-modal-btn"
                      @click="showmodelQuizGroup()"
                    >
                      New Group
                    </button>
                  </div>
                  <div
                    class="d-flex justify-content-end align-items-center d-none"
                    data-kt-subscription-table-toolbar="selected"
                  >
                    <div class="fw-bolder me-5">
                      <span
                        class="me-2"
                        data-kt-subscription-table-select="selected_count"
                      ></span
                      >Selected
                    </div>
                    <button
                      type="button"
                      class="btn btn-danger"
                      data-kt-subscription-table-select="delete_selected"
                    >
                      Delete Selected
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="table-responsive">
                  <div
                    id="group_table_wrapper"
                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div class="table-responsive">
                      <table
                        class="
                          table
                          align-middle
                          table-row-dashed
                          fs-6
                          gy-5
                          dataTable
                          no-footer
                        "
                        id="group_table"
                        style="width: 0px"
                      >
                        <thead>
                          <tr
                            class="
                              text-start text-muted
                              fw-bolder
                              fs-7
                              text-uppercase
                              gs-0
                            "
                          >
                            <th class="pe-2" style="width: 3% !important">
                              <div
                                class="
                                  form-check
                                  form-check-sm
                                  form-check-custom
                                  form-check-solid
                                  me-3
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  data-kt-check="true"
                                  data-kt-check-target="#group_table .form-check-input"
                                  value="1"
                                />
                              </div>
                            </th>
                            <th>Group</th>
                            <th>Quiz</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody
                          class="text-gray-600 fw-bold"
                          v-if="getquizGroup.length"
                        >
                          <tr
                            class="odd"
                            v-for="(g, index) in getquizGroup"
                            :key="index"
                          >
                            <td>
                              <div
                                class="
                                  form-check
                                  form-check-sm
                                  form-check-custom
                                  form-check-solid
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="1"
                                />
                              </div>
                            </td>
                            <td>{{ g.name }}</td>
                            <td>
                              {{ g.quiz_count }}
                            </td>
                            <td>
                              <div
                                class="
                                  d-flex
                                  justify-content-start
                                  flex-shrink-0
                                "
                              >
                                <button
                                  class="
                                    btn
                                    btn-icon
                                    btn-bg-light
                                    btn-circle
                                    btn-active-color-primary
                                    btn-sm
                                    me-1
                                    edit-group-btn
                                  "
                                  data-id="16"
                                  @click="editQuizGroup(g)"
                                >
                                  <span class="svg-icon svg-icon-3">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                        fill="black"
                                      ></path>
                                      <path
                                        d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                        fill="black"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                                <a
                                  href="javascript:void(0)"
                                  @click="deleteGroup(g.id)"
                                  class="
                                    btn
                                    btn-icon
                                    btn-bg-light
                                    btn-circle
                                    btn-active-color-primary
                                    btn-sm
                                  "
                                >
                                  <span class="svg-icon svg-icon-3">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                        fill="black"
                                      ></path>
                                      <path
                                        opacity="0.5"
                                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                        fill="black"
                                      ></path>
                                      <path
                                        opacity="0.5"
                                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                        fill="black"
                                      ></path>
                                    </svg>
                                  </span>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="4">No Groups Found.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Model
    :modaltitle="modaltitle"
    @close="CloseModelQuiz()"
    v-if="showmodalQuiz"
  >
    <template #body>
      <form method="post" @submit.prevent="addQuiz()">
        <div class="modal-body">
          <div class="mb-10 fv-row fv-plugins-icon-container">
            <label for="exampleFormControlInput1" class="required form-label"
              >Name of Quiz</label
            >
            <input
              type="text"
              class="form-control form-control-solid"
              :class="{
                'is-invalid custom-border': v$.quiz.name.$errors.length,
              }"
              v-model="v$.quiz.name.$model"
              placeholder="Enter Name of Quiz"
            />
            <span
              class="invalid-feedback"
              role="alert"
              v-for="(error, index) of v$.quiz.name.$errors"
              :key="index"
            >
              <strong>Name is required</strong>
            </span>
          </div>
          <div class="mb-10 fv-row fv-plugins-icon-container">
            <label for="" class="form-label">Quiz Group</label>
            <select
              class="form-select form-select-solid"
              :class="{
                'is-invalid custom-border': v$.quiz.group_id.$errors.length,
              }"
              v-model="v$.quiz.group_id.$model"
            >
              <option
                v-for="(g, index) in getquizGroup"
                :key="index"
                :value="g.id"
              >
                {{ g.name }}
              </option>
            </select>
            <span
              class="invalid-feedback"
              role="alert"
              v-for="(error, index) of v$.quiz.group_id.$errors"
              :key="index"
            >
              <strong>Group ID is required</strong>
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="CloseModelQuiz()"
          >
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </template>
  </Model>
  <Model
    :modaltitle="modaltitle"
    @close="CloseModelQuizGroup()"
    v-if="showmodalQuizGroup"
  >
    <template #body>
      <form method="post" @submit.prevent="addGroup()">
        <div class="modal-body">
          <div class="mb-10 fv-row fv-plugins-icon-container">
            <label for="exampleFormControlInput1" class="required form-label"
              >Group Name</label
            >
            <input
              type="text"
              class="form-control form-control-solid"
              :class="{
                'is-invalid custom-border': v$.quizGroup.name.$errors.length,
              }"
              v-model="v$.quizGroup.name.$model"
              placeholder="Enter Name of Quiz Group"
            />
            <span
              class="invalid-feedback"
              role="alert"
              v-for="(error, index) of v$.quizGroup.name.$errors"
              :key="index"
            >
              <strong>Name is required</strong>
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="CloseModelQuizGroup()"
          >
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </template>
  </Model>
</template>
<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Model from "@/components/custom-components/model.vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import LaravelVuePagination from "laravel-vue-pagination";
import _ from "lodash";
import moment from "moment";
export default defineComponent({
  name: "dashboard",
  components: {
    Model,
    Pagination: LaravelVuePagination,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Quiz");
    });
    const toast = useToast();
    return { toast, v$: useVuelidate() };
  },
  data() {
    return {
      showmodalQuiz: false,
      showmodalQuizGroup: false,
      quiz_serch: "",
      active_tab: "quiz",
      modaltitle: "Add Quiz",
      quiz: {
        name: "",
        group_id: "",
      },
      quizGroup: {
        name: "",
      },
      current_page: 1,
      sort_group_id: "",
      quiz_name_search: "",
      loader:false
    };
  },
  validations() {
    return {
      quiz: {
        name: { required },
        group_id: { required },
      },
      quizGroup: {
        name: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      getquizGroup: "Quiz/getquizGroupData",
      getquizDataGT: "Quiz/getquizData",
    }),
    format_date() {
      return (value) => {
        return moment(value).format("MM/DD/YYYY");
      };
    },
  },
  methods: {
    ...mapActions({
      addQuizGroup: "Quiz/addQuizGroup",
      addQuizData: "Quiz/addQuiz",
      setquizGroup: "Quiz/getQuizGroup",
      setgetQuiz: "Quiz/getQuiz",
      deleteQuizGroup: "Quiz/deleteGroup",
      deleteQuiz: "Quiz/deleteQuiz",
      cloneQuiz:"Quiz/cloneQuiz"
    }),
    cloneQuizData(q){
      this.$swal
        .fire({
          title: "Are you sure you want to copy?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, copy it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loader=true
            this.cloneQuiz(q.id).then((res)=>{
            this.loader=false
                if (res.data.status == true) {
                  this.$swal.fire(
                    "Copied!",
                    "Quiz Copied Successfully.",
                    "success"
                  );
                }
              })
              .catch((error) => {
                this.loader=false
                this.toast.error(error.response.data.message);
              });
          }
        });
      // this.cloneQuiz(q.id).then((res)=>{
      //   if(res.data.status==true){
      //   this.toast.success('Quiz Copied Successfully','Copied!');
      //   }
      // })
    },
    eventcheck(){
      console.log(121)
    },
    search_group: _.debounce(function () {
      this.getAllgroups();
    }, 500),
    search_quiz: _.debounce(function () {
      this.getquizData();
    }, 500),
    showmodelQuiz() {
      (this.modaltitle = "Add Quiz"),
        (this.quiz = {
          id:"",
          name: "",
          group_id: "",
        });
      this.showmodalQuiz = true;
      this.v$.quiz.$reset();
    },
    showmodelQuizGroup() {
      (this.modaltitle = "Add Quiz Group"),
        (this.quizGroup = {
          name: "",
        });
      this.showmodalQuizGroup = true;
      this.v$.quizGroup.$reset();
    },
    CloseModelQuiz() {
      this.showmodalQuiz = false;
    },
    copyScript(data){
        let copyUrl = process.env.VUE_APP_API_URL+"/page/quiz/"+data.unique_code
        var scriptRoute = process.env.VUE_APP_API_URL+'/assets/quiz/script.js'
        // eslint-disable-next-line no-useless-escape
        var iFrame = '<iframe id="QuizForma-'+data.unique_code+'" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="'+copyUrl+'" frameborder="0" width="100%" height="539px" scrolling="no"></iframe><script type="text/javascript" src="'+scriptRoute+'" quiz-unique-code="'+data.unique_code+'"></\script>'
        document.addEventListener('copy', function(e) {
            e.clipboardData.setData('text/plain', iFrame);
            e.preventDefault();
        }, true);
        document.execCommand('copy');
        this.toast.success('Script Copied Successfully','Copied!');
      },
    editQuizGroup(data) {
      (this.modaltitle = "Edit Quiz Group"),
        (this.quizGroup = {
          name: data.name,
          id: data.id,
        });
      this.showmodalQuizGroup = true;
      this.v$.quizGroup.$reset();
    },
    editQuiz(data){
      (this.modaltitle = "Update Quiz"),
        (this.quiz = {
          id:data.id,
          name:data.name,
          group_id:data.group_id,
        });
      this.showmodalQuiz = true;
      this.v$.quiz.$reset();
    },
    CloseModelQuizGroup() {
      this.showmodalQuizGroup = false;
    },
    addQuiz() {
      this.v$.quiz.$touch();
      if (this.v$.quiz.$invalid) {
        return;
      }
      this.addQuizData(this.quiz)
        .then((response) => {
          if (response.data.status == true) {
            this.showmodalQuiz = false;
          }
          this.sort_group_id = "";
          this.quiz_name_search = "";
          //   this.quiz_serch=''
        })
        .catch((error) => {
          this.toast.error(error.response.data.message);
        });
    },
    addGroup() {
      this.v$.quizGroup.$touch();
      if (this.v$.quizGroup.$invalid) {
        return;
      }
      this.addQuizGroup(this.quizGroup)
        .then((response) => {
          if (response.data.status == true) {
            this.showmodalQuizGroup = false;
          }
          this.quiz_serch = "";
        })
        .catch((error) => {
          this.toast.error(error.response.data.message);
        });
    },
    deleteGroup(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteQuizGroup(id)
              .then((response) => {
                if (response.data.status == true) {
                  this.$swal.fire(
                    "Deleted!",
                    "Quiz Group has been deleted.",
                    "success"
                  );
                  this.quiz_serch = "";
                }
              })
              .catch((error) => {
                this.toast.error(error.response.data.message);
              });
          }
        });
    },
    deleteConQuiz(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteQuiz(id)
              .then((response) => {
                if (response.data.status == true) {
                  this.$swal.fire(
                    "Deleted!",
                    "Quiz has been deleted.",
                    "success"
                  );
                  this.getquizData(this.current_page);
                }
              })
              .catch((error) => {
                this.toast.error(error.response.data.message);
              });
          }
        });
    },
    getAllgroups() {
      this.setquizGroup(this.quiz_serch);
    },
    getquizData(page = 1) {
      this.current_page = page;
      let data = {
        page: page,
        sort_group_id: this.sort_group_id,
        query: this.quiz_name_search,
      };
      this.setgetQuiz(data);
    },
    copyLink(uniqueCode){
      let copyUrl = process.env.VUE_APP_API_URL+"/page/quiz/"+uniqueCode
      document.addEventListener('copy', function(e) {
      e.clipboardData.setData('text/plain', copyUrl);
          e.preventDefault();
      }, true);
      document.execCommand('copy');
      this.toast.success('Link Copied Successfully','Copied!');
      window.open(copyUrl, '_blank');
      
    },
  },
  created() {
    this.getquizData();
    this.getAllgroups();
  },
});
</script>
<style scoped>
.parent {
  font-weight: bold;
  text-transform: uppercase;
}
.group-dropdown {
  margin-right: 15px;
}
.add-button {
  width: 65px !important;
}
.search-input {
  margin-right: 15px;
}
.edit-name {
  padding: 1px 10px 1px 6px;
  margin-right: 6px;
  width: 50%;
  border: 2px dashed #eff2f5;
}
.edit-name-show {
  padding: 1px 5px 1px 6px;
}
.tag-modal {
  max-width: 650px;
}
.border-2 {
  color: #ffffff !important;
}
.custom-border {
  border-color: #f1416c !important;
}
</style>
